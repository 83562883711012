import React from 'react'
import { Link } from "gatsby"

import Main from '../layouts/main'
import Hero from '../components/hero'

import styles from './index.module.scss'


const IndexPage = () => (
  <Main>
    <Hero
      backgroundImage='homeBackground'
      backgroundOverlay=''
    >
      <div className={`${styles.container} ${styles.heroLarge}`}>
        <div className={styles.intro}>
          <h1 className={`${styles.fadeInOne} ${styles.heroText} ${styles.title1}`}>
            WE’RE ELEMENTIVE —
          </h1>
          <h2 className={`${styles.fadeInTwo} ${styles.heroText} ${styles.title2}`}>
            A SOFTWARE & ANALYTICS CONSULTANCY
          </h2>
        </div>
      </div>
    </Hero>
    <div className={styles.gridAlpha}>
      <div className={styles.colSmall}>
        <h3 className={`${styles.homeh3} ${styles.fadeInOne}`}>
          A Software and Analytics Consultancy Built for the Modern Web
        </h3>
        <hr className={styles.homeHr}/>
      </div>
      <div className={styles.colLarge}>
        <p className={styles.homeP}>
          Elementive is an engineering and analytics consultancy, specializing in python, javascript and their extended ecosystems.
        </p>
        <p className={styles.homeP}>
          Since 2016, we have worked with companies to build quality software and level up organizations.
        </p>
        <p className={styles.homeP}>
          Launching a new product? Need Help with an existing project?
        </p>
        <Link to="/contact/">
          <button className={`${styles.button} ${styles.buttonMargin}`}>
            Let's Get Started
          </button>
        </Link>
      </div>
    </div>
  </Main>
)

export default IndexPage

